// nyweb2 xperimental-word-break-detect.less

/*
See related script for documentation.
*/

.word-break-detect {
  // Set overflow wrap to normal to allow overflow
  overflow-wrap: normal;
  // JS-detect overflow
  // Re-add overflow styles and include hyphens if overflows
  &.overflows {
    -webkit-hyphens: auto;
    hyphens: auto;
    overflow-wrap: anywhere;
  }
}
