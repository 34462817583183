// nyweb2 xperimental-grade-table-calculator.less

.grade-table-calculator {
  border: 1px solid var(--color-neutral-tint-80);
  padding: 30px;
  margin-bottom: 30px;
  @media (@screen-below-medium) {
    padding: 20px;
  }
  .heading {
    font-size: 2rem;
  }
  label,
  [role="status"] {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  label {
    width: fit-content;
    margin: 10px 0;
    p::after {
      content: ":";
    }
  }
  input {
    background: var(--color-neutral-tint-95);
    width: 4em;
  }
  p {
    margin: 0;
  }
  [role="status"] {
    margin-bottom: 20px;
    span {
      background-color: black;
      color: white;
      padding: 0 5px;
    }
  }
  &.user-interacted {
    [role="status"] {
      min-height: 1.5em;
    }
  }
}
// Control the table. Class added with js
.calculated-table {
  &:not(.expanded) {
    display: none;
  }
  th, td {
    &.match {
      outline: 3px solid black;
      outline-offset: -3px;
      // Style second cell. Importants in case of inline styles.
      & + .match {
        background-color: black !important;
        color: white !important;
      }
    }
  }
}

