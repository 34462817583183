// nyweb2 xperimental-grade-table-calculator.less

.grade-table-calculator {
  display: grid;
  gap: 10px;
  border: 1px solid #ccc;
  padding: 30px;
  @media (@screen-below-medium) {
    padding: 15px;
  }
  label {
    width: fit-content;
  }
  input {
    background: #f5f5f5;
    width: 4em;
    @media (@screen-below-medium) {
      margin: 5px 0 10px;
    }
  }
  p {
    margin: 0;
  }
  strong:not(.heading) {
    font-weight: normal;
    margin-right: 10px;
    &::after {
      content: ":";
    }
    @media (@screen-below-medium) {
      display: block;
    }
  }
  div[aria-live] {
    margin-bottom: 10px;
    span {
      font-size: 3rem;
      font-weight: bold;
      @media (@screen-medium) {
        // Maintain height based on root size, for vertical balance
        line-height: 1.5rem;
      }
    }
    &.error {
      strong {
        display: none;
      }
      span {
        font-size: inherit;
        color: #c00;
      }
    }
  }
  button {
    width: fit-content;
  }
}
// The table
table.grade-calculate {
  th, td {
    &.match {
      outline: 3px solid;
      outline-offset: -3px;
    }
  }
}
// Toggle view of table
.grade-table-calculator:has(button[aria-expanded="false"]) {
  margin-bottom: 30px;
  & + table {
    display: none;
  }
}