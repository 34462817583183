// nyweb2 xperimental-grade-table-calculator.less

.grade-table-calculator {
  border: 1px solid #ccc;
  padding: 30px;
  margin-bottom: 30px;
  @media (@screen-below-medium) {
    padding: 20px;
  }
  .heading {
    font-size: 2rem;
  }
  label,
  [role="status"] {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  label {
    width: fit-content;
    margin: 15px 0;
  }
  input {
    background: #f5f5f5;
    width: 3.5em;
  }
  p {
    margin: 0;
    &::after {
      content: ":";
    }
  }
  [role="status"] {
    margin-bottom: 25px;
    span {
      font-weight: bold;
    }
    &:not(.error) {
      span {
        flex-shrink: 0;
        font-size: 3rem;
        line-height: 1.5rem; // Maintain height matching base size
      }
    }
    &.error {
      p {
        display: none;
      }
      span {
        font-size: inherit;
        color: #c00;
      }
    }
  }
  // Hide status message until started using calculator
  &:not(.active) {
    [role=status] {
      display: none;
    }
  }
  button {
    width: fit-content;
  }
}
// Control the table. Class added with js
.calculated-table {
  &:not(.expanded) {
    display: none;
  }
  th, td {
    &.match {
      outline: 3px solid black;
      outline-offset: -3px;
      // Style second cell. Importants in case of inline styles.
      & + .match {
        background-color: black !important;
        color: white !important;
      }
    }
  }
}
