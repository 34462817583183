// nyweb2 xperimental-sticky-aside.less


.vrtx-article-body {
  .sticky-left,
  .sticky-right {
    clear: both;
    width: 300px;
    margin: 10px 40px 40px 40px;
    @media (@screen-below-medium) {
      max-width: 70vw;
      margin-right: auto;
      margin-left: auto;
    }
  }
  .sticky-left {
    @media (@screen-medium) {
      float: left;
      margin-left: 0;
    }
    @media (@screen-large) {
      margin-left: -150px;
    }
  }
  .sticky-right {
    @media (@screen-medium) {
      float: right;
      margin-right: 0;
    }
    @media (@screen-large) {
      margin-right: -150px;
    }
  }
}
// Update oct. 2024 by Kjetil. Keep vh units out of vrtx-preview
body:not(.vrtx-mode-preview) {
  .vrtx-article-body {
    .sticky-left,
    .sticky-right {
      // Height determines sticky distance
      @media (@screen-medium) {
        height: 100vh;
        img {
          position: sticky;
          top: 50px;
        }
      }
      @media (@screen-large) {
        height: 150vh;
      }
    }
  }
}