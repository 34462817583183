// nyweb2 local.less

// X imports
@import "../../../../../../../lib/x1/css/x-0.less";
@import "../../../../../../../lib/x1/css/optional/x-consent-subtle.less";

// Local imports
@import "xperimental-grade-table-calculator.less";
@import "xperimental-sticky-aside.less";
@import "xperimental-text-size-scale.less";
@import "xperimental-word-break-detect.less";








// English use same logo as NO
html[lang=en] #header {
  .logo {
    background-image: url("../images/logo.svg");
  }
}

// Enable background-video
.vrtx-background-video {
  &::before {
    display: none;
  }
  video {
    opacity: 1;
  }
}

// Hide RSS link
#vrtx-feed-link,
.vrtx-feed-link {
  display: none;
}

// Study-program-finder prototype style (HTML-include in frontpage template)
.grid-container.study-program-finder-row {
  background-color: var(--color-neutral-tint-95);
  padding: 20px 0;
}
