// nyweb2 xperimental-text-size-scale.less


.text-size-scale-container {
  max-width: var(--width-soft-max);
  border: 1px dashed #ccc;
}

.text-size-scale {
  font-size: 15rem;
  line-height: 1;
  margin-bottom: 100px;
  overflow-wrap: normal;
  &.long-text {
    font-size: 6rem;
    line-height: 1.2;
    overflow-wrap: anywhere;
  }
}